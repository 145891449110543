<template>
    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
        <button class="pattenbutton" @click="showpanel">패턴분석기</button>
        <div class="pattenwrap_powerball" :class="{'active':isshow}">
            <div class="typebuttons">
                <button @click="change(1)" :class="{'bactive':currenttap == 1}">(파) 홀짝</button>
                <button @click="change(2)" :class="{'bactive':currenttap == 2}">(파) 오언</button>
                <button @click="change(3)" :class="{'bactive':currenttap == 3}">(일) 홀짝</button>
                <button @click="change(4)" :class="{'bactive':currenttap == 4}">(일) 오언</button>
            </div>
            <div class="percent">
                <span v-if="currenttap == 1">
                    <span style="color: lightskyblue">홀({{powerball['poddeven_odd_count']}} 회 , {{powerball['poddeven_odd_percent']}}%)</span>
                    <span style="color: red;margin-left: 5px">짝({{powerball['poddeven_even_count']}} 회 , {{powerball['poddeven_even_percent']}}%)</span>
                </span>
                <span v-if="currenttap == 2">

                    <span style="color: lightskyblue;">언더({{powerball['poverunder_under_count']}} 회 , {{powerball['poverunder_under_percent']}}%)</span>
                      <span style="color: red;margin-left: 5px">오버({{powerball['poverunder_over_count']}} 회 , {{powerball['poverunder_over_percent']}}%)</span>
                </span>
                <span v-if="currenttap == 3">
                    <span style="color: lightskyblue">홀({{powerball['oddeven_odd_count']}} 회 , {{powerball['oddeven_odd_percent']}}%)</span>
                    <span style="color: red;margin-left: 5px">짝({{powerball['oddeven_even_count']}} 회 , {{powerball['oddeven_even_percent']}}%)</span>
                </span>
                <span v-if="currenttap == 4">
                    <span style="color: lightskyblue;">언더({{powerball['overunder_under_count']}} 회 , {{powerball['overunder_under_percent']}}%)</span>
                     <span style="color: red;margin-left: 5px">오버({{powerball['overunder_over_count']}} 회 , {{powerball['overunder_over_percent']}}%)</span>
                </span>
            </div>
            <div class="panel">
                <div v-for="ball in powerball_poddeven" class="typepanel" v-if="currenttap == 1">
                    <div v-if="Object.keys(ball)[0] == '홀'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '홀'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>

                </div>

                <div v-for="ball in powerball_poverunder" class="typepanel" v-if="currenttap == 2">
                    <div v-if="Object.keys(ball)[0] == '오버'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '언더'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '오버'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '언더'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>

                </div>

                <div v-for="ball in powerball_oddeven" class="typepanel" v-if="currenttap == 3">
                    <div v-if="Object.keys(ball)[0] == '홀'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '홀'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>

                </div>

                <div v-for="ball in powerball_overunder" class="typepanel" v-if="currenttap == 4">
                    <div v-if="Object.keys(ball)[0] == '오버'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '언더'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '오버'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '언더'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {getParrents} from "../../network/leisureRequest";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGamePattenPowerballComp",
        props: {
            kind: {
                type: Number,
                default() {
                    return 0;
                }
            },
        },
        data() {
            return {
                pattenMap: null,
                powerball:[],
                powerball_poddeven: [],
                powerball_poverunder: [],
                powerball_oddeven: [],
                powerball_overunder: [],
                isshow:false,
                currenttap:1
            }
        },
        methods: {
            change(ct){
                this.currenttap = ct;
            },
            pattens() {
                getParrents(this.kind).then(res => {
                    this.pattenMap = res.data.data;
                    this.powerball = this.pattenMap['powerball'];
                    this.powerball_poddeven = this.powerball['poddeven']
                    this.powerball_oddeven = this.powerball['oddeven']
                    this.powerball_poverunder = this.powerball['poverunder']
                    this.powerball_overunder = this.powerball['overunder']
                })
            },
            showpanel(){
                this.isshow =!this.isshow
                if(this.isshow){
                    this.pattens()
                }
            }
        },
        created() {
           // this.pattens();
        },
        mounted() {
            setTimeout(()=>{
                console.log(document.querySelector('.panel').scrollWidth)
                document.querySelector('.panel').scrollLeft =  document.querySelector('.panel').scrollWidth;

            },1000)

        }
    }
</script>

<style scoped>
    .bactive{
        background-color: #000000;
    }
    .pattenbutton {
        width: 100%;
        height: 32px;
        background-color: #262626;
    }
    .pattenwrap_powerball{
        width: 100%;
        display: none;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .pattenwrap_powerball .typebuttons{
        width: 100%;
        display: flex;align-items: center;justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 1px;
    }
    .pattenwrap_powerball .typebuttons button{
        width: 24.7%;
        height: 32px;
        border: 1px solid #5c5c5c;
    }
    .pattenwrap_powerball .percent{
        width: 100%;
        display: flex;align-items: center;justify-content: center;
        flex-wrap: nowrap;
        margin-top: 1px;
        height: 32px;
    }
    .panel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 2px;
        width: 100%;
        overflow: scroll;
        margin-top: 5px;
        max-height: 400px;
    }

    .panel .typepanel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 5px;
        font-size: 10px;
    }

    .typepanel .title {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .typepanel .t1 {
        color: lightskyblue;
    }

    .typepanel .t2 {
        color: red;
    }

    .typepanel .b {
        width: 30px;
        height: 30px;
        margin: 1px 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .typepanel .b1 {
        background-color: lightskyblue;
    }

    .typepanel .b2 {
        background-color: red;
    }
    .active{
        display: flex!important;
    }
</style>